import React, { Component } from 'react';
import {
  Modal,
  Row,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Col,
  Button,
} from 'react-bootstrap';
import Auth from '@aws-amplify/auth';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import { yyyymmdd } from '../../libs/date_convert';
import { CreateProductionLineMutation, Items, Order } from '../../libs/GraphQL';
import ConfirmationModal from './ConfirmationModal';
import { lineOptions, lineLeadsOptions } from './constants';

export default class CreateProductionLineItem extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.saveWork = this.saveWork.bind(this);
    this.openSubmitModal = this.openSubmitModal.bind(this);
    this.afterSaveCell = this.afterSaveCell.bind(this);
    this.showSummary = this.showSummary.bind(this);
    this.addOwner = this.addOwner.bind(this);
    this.state = {
      toProduce: {},
      toSubmit: [],
      items: [],
      submitModal: {
        show: false,
      },
      selected: [],
      itemQts: [],
      productionLineItem: {
        productionFacility: 'CHICAGO_1',
        state: '',
        owner: '',
      },
      alertModal: {
        mode: false,
        text: [],
      },
      confirmModal: {
        show: false,
        quantity: 0,
        forecast: 0,
        id: '',
      },
      disabled: false,
    };
  }

  async componentDidMount() {
    this.getLiveVendItems();
    const today = yyyymmdd(new Date(), '-');
    let order = await Order(null, today, null);
    let orderState = order.data.ordersByDay.orders;
    while (order.data.ordersByDay.nextToken !== null) {
      order = await Order(order.data.ordersByDay.nextToken, today, null);
      orderState = orderState.concat(order.data.ordersByDay.orders);
    }
    const finalCount = {};
    for (const item in orderState) {
      for (const iCount in orderState[item].itemsCount) {
        const ic = orderState[item].itemsCount[iCount];
        if ('productionDate' in ic && ic.productionDate === today) {
          if (!finalCount.hasOwnProperty(ic.itemName)) {
            finalCount[ic.itemName] = 0;
          }
          finalCount[ic.itemName] += ic.count;
        }
      }
    }
    this.setState({ toProduce: finalCount });
  }

  async getLiveVendItems() {
    try {
      const itemsRaw = await Items();
      const items = itemsRaw.data.items.filter(
        (item) => !item.Categories || !item.Categories.includes('Drinks'),
      );
      this.setState({
        items: items.sort(function (a, b) {
          const keyA = a.Id;
          const keyB = b.Id;
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        }),
        itemQts: items.map((item) => ({
          itemName: item.id,
          prettyName: item.prettyName,
          quantity: 0,
          notes: 'Click here to add notes',
          issueType: 'None',
          line: 'None',
          startTime: 'None',
          endTime: 'None',
          breakStartTime: 'None',
          breakEndTime: 'None',
          linePopulation: 0,
          lineLead: 'None',
          toProduce: 0,
        })),
      });
    } catch (e) {
      console.log(e);
      const alertModal = this.state.alertModal;
      alertModal.mode = true;
      alertModal.text = [
        'Unable to load Vend items, Please check your internet and your permissions',
      ];
      this.setState({ alertModal });
    }
  }

  handleOnSelect = (row, isSelect) => {
    const newSelected = new Set(this.state.selected);
    if (isSelect) {
      newSelected.add(row.id);
    } else {
      newSelected.delete(row.id);
    }
    this.setState({ selected: [...newSelected] });
  };

  afterSaveCell(oldValue, newValue, row, column) {
    if (
      [
        'notes',
        'issueType',
        'line',
        'startTime',
        'endTime',
        'breakStartTime',
        'breakEndTime',
        'linePopulation',
        'lineLead',
      ].includes(column.dataField)
    ) {
      if (oldValue === newValue) {
        return;
      }
      console.log(newValue);
      const details = this.state.itemQts;
      for (const key in details) {
        if (details[key].itemName === row.id) {
          details[key][column.dataField] = newValue;
          break;
        }
      }
      this.setState({ itemQts: details });
    } else {
      if (parseInt(oldValue, 10) === parseInt(newValue, 10)) {
        return;
      }
      const details = this.state.itemQts;
      for (const key in details) {
        if (details[key].itemName === row.id) {
          details[key].quantity = parseInt(newValue, 10);
          break;
        }
      }
      this.setState({ itemQts: details });
    }
  }

  handleChange(e) {
    const productionLineItem = this.state.productionLineItem;
    productionLineItem[e.target.id] = e.target.value;
    this.setState({ productionLineItem });
  }

  isValidTime(timeStr) {
    // validate "HH:MM" time format with regular expression
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
    return typeof timeStr === 'string' && timePattern.test(timeStr);
  }

  isValidInput() {
    const productionLineItem = JSON.parse(
      JSON.stringify(this.state.productionLineItem),
    );
    for (const key in productionLineItem) {
      if (productionLineItem[key].trim().length === 0) {
        return false;
      }
    }
    const quantities = this.state.itemQts;
    const toSubmitArray = [];
    for (const key in quantities) {
      if (this.state.selected.includes(quantities[key].itemName)) {
        const objCopy = { ...productionLineItem };
        let itemName = quantities[key].itemName;
        console.log(productionLineItem, objCopy);
        if (
          productionLineItem.state === 'PRODUCED' &&
          quantities[key].line === 'None'
        ) {
          return false;
        }
        if (productionLineItem.state === 'PRODUCED') {
          const startTime = quantities[key].startTime;
          const endTime = quantities[key].endTime;

          if (!this.isValidTime(startTime) || !this.isValidTime(endTime)) {
            return false;
          }

          objCopy.line = quantities[key].line;
          objCopy.linePopulation = quantities[key].linePopulation;
          objCopy.startTime = quantities[key].startTime;
          objCopy.endTime = quantities[key].endTime;
          objCopy.breakStartTime = quantities[key].breakStartTime;
          objCopy.breakEndTime = quantities[key].breakEndTime;
          objCopy.lineLead = quantities[key].lineLead;
        }
        objCopy.itemName = itemName;
        objCopy.quantity = quantities[key].quantity;
        objCopy.issueType = quantities[key].issueType;
        objCopy.notes = quantities[key].notes;
        objCopy.toProduce = this.state.toProduce[itemName];
        toSubmitArray.push(objCopy);
      }
    }
    return toSubmitArray;
  }

  async openSubmitModal() {
    const response = await this.addOwner();
    if (response) {
      const subModal = this.state.submitModal;
      subModal.show = true;
      this.setState({ submitModal: subModal });
    }
  }

  async saveWork(e) {
    const subModal = this.state.submitModal;
    subModal.show = false;
    this.setState({ submitModal: subModal });
    e.preventDefault();
    const res = this.isValidInput();
    if (!res) {
      const alertModal = this.state.alertModal;
      alertModal.mode = true;
      alertModal.text = [
        'To submit this checkpoint Please fill all values highlighted in red (Production Facility, Team) or check that startTime, endTime match "HH:MM" format',
      ];
      this.setState({ alertModal });
      return;
    }
    const toSubmitArray = res;
    const alertMessages = [];
    if (res.length === 0) {
      alertMessages.push(
        'No items to submit. Atleast one item should be non zero for this checkpoint to be submitted',
      );
    }
    for (const key in toSubmitArray) {
      const productionLineItem = JSON.parse(JSON.stringify(toSubmitArray[key]));
      console.log(productionLineItem);
      productionLineItem.quantity = parseInt(productionLineItem.quantity, 10);
      productionLineItem.team = productionLineItem.state;
      try {
        const response = await CreateProductionLineMutation(productionLineItem);
        this.setState({
          selected: [],
        });
        this.getLiveVendItems();
        alertMessages.push(
          `Successfully created the checkpoint for ${productionLineItem.itemName}`,
        );
        console.log(response);
      } catch (e) {
        const errorString = JSON.stringify(e);
        const errorObject = JSON.parse(errorString);
        console.log(errorObject);
        if ('errors' in errorObject && 'message' in errorObject.errors) {
          alertMessages.push(
            `${errorObject.errors.message} ${productionLineItem.itemName}`,
          );
        } else {
          alertMessages.push(
            `Unable to Create a checkpoint, Please check your internet and your permissions. ${productionLineItem.itemName}`,
          );
        }
      }
    }
    const alertModal = this.state.alertModal;
    alertModal.mode = true;
    alertModal.text = alertMessages;
    this.setState({ alertModal });
  }

  getValidationState(type) {
    const length = this.state.productionLineItem[type].length;
    if (length > 0) return 'success';
    if (length === 0) return 'error';
    return null;
  }

  async addOwner() {
    const currentSession = await Auth.currentAuthenticatedUser();
    const productionLineItem = this.state.productionLineItem;
    try {
      const email = currentSession.signInUserSession.idToken.payload.email;
      if (!email.includes('@')) {
        throw new Error('There is no email present');
      }
      productionLineItem.owner =
        currentSession.signInUserSession.idToken.payload.email;
      this.setState({ productionLineItem });
    } catch (e) {
      const alertModal = this.state.alertModal;
      alertModal.mode = true;
      alertModal.text = ['Please reload the page'];
      this.setState({ alertModal });
      return false;
    }
    return true;
  }

  showSummary() {
    const toSubmitArray = [];
    let toSubmitItems = [];
    for (const key in this.state.itemQts) {
      if (this.state.selected.includes(this.state.itemQts[key].itemName)) {
        toSubmitArray.push(this.state.itemQts[key]);
      }
    }
    if (toSubmitArray.length === 0) {
      toSubmitItems = (
        <tr key="NO-Data">
          <td key="NO-Data"> No Items to submit</td>
        </tr>
      );
    } else {
      toSubmitItems = toSubmitArray.map((obj) => (
        <tr key={obj.itemName}>
          <td>{obj.itemName}</td>
          {this.state.productionLineItem.state === 'PRODUCED' && (
            <td>{obj.line}</td>
          )}
          {this.state.productionLineItem.state === 'PRODUCED' && (
            <td>{obj.linePopulation}</td>
          )}
          {this.state.productionLineItem.state === 'PRODUCED' && (
            <td>{obj.lineLead}</td>
          )}
          {this.state.productionLineItem.state === 'PRODUCED' && (
            <td>{obj.startTime}</td>
          )}
          {this.state.productionLineItem.state === 'PRODUCED' && (
            <td>{obj.endTime}</td>
          )}
          {this.state.productionLineItem.state === 'PRODUCED' && (
            <td>{obj.breakStartTime}</td>
          )}
          {this.state.productionLineItem.state === 'PRODUCED' && (
            <td>{obj.breakEndTime}</td>
          )}
          <td>{obj.quantity}</td>
          <td>{obj.issueType}</td>
          <td>{obj.notes}</td>
        </tr>
      ));
    }
    return toSubmitItems;
  }

  render() {
    let alertRows = [];
    const alertMessages = this.state.alertModal.text;
    alertRows = alertMessages.map((text) => (
      <div key={text}>
        <p>{text}</p>
        <br />
      </div>
    ));

    return (
      <div>
        {this.state.confirmModal.show && (
          <Modal
            show={this.state.confirmModal.show}
            className="prodLineCheckpointConfirm"
            bgSize="large"
          >
            <Modal.Body>
              <div className="form-group ">
                <h3 className="confirmTitle">
                  Received count is +/- 10% of the production plan value for{' '}
                  {this.state.confirmModal.id}. Is this correct?
                </h3>
                <h3>Plan Count: {this.state.confirmModal.forecast}</h3>
                <h3>Entered Count: {this.state.confirmModal.quantity}</h3>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                bsStyle="warning"
                onClick={() => {
                  const confirmModal = this.state.confirmModal;
                  confirmModal.show = false;
                  this.setState({ confirmModal });
                }}
              >
                GO BACK & EDIT
              </Button>
              <Button
                bsStyle="success"
                onClick={() => {
                  const confirmModal = this.state.confirmModal;
                  confirmModal.show = false;
                  this.setState({ confirmModal });
                }}
              >
                YES
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {this.state.submitModal.show && (
          <ConfirmationModal
            submitData={this.showSummary()}
            productionLineItem={this.state.productionLineItem}
            close={() => {
              const submitModal = this.state.submitModal;
              submitModal.show = false;
              this.setState({ submitModal });
            }}
            save={this.saveWork}
          />
        )}
        {this.state.alertModal.mode && (
          <Modal show={this.state.alertModal.mode}>
            <Modal.Body>
              <div className="form-group">{alertRows}</div>
            </Modal.Body>
            <Modal.Footer>
              &nbsp;
              <Button
                bsStyle="warning"
                onClick={() => {
                  const alertModal = this.state.alertModal;
                  alertModal.mode = false;
                  this.setState({ alertModal });
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        <h2>Production Line Checkpoint</h2>
        <Row>
          <Col sm={12}>
            <br />
            <Form inline>
              <FormGroup
                controlId="productionFacility"
                validationState={this.getValidationState('productionFacility')}
              >
                <ControlLabel>Production Facility</ControlLabel>{' '}
                <FormControl
                  componentClass="select"
                  placeholder="select"
                  value={this.state.productionLineItem.productionFacility}
                  onChange={this.handleChange}
                >
                  <option value="">Choose Facility</option>
                  <option value="CHICAGO_1">CHICAGO_1</option>
                </FormControl>
                <FormControl.Feedback />
              </FormGroup>{' '}
              <FormGroup
                controlId="state"
                validationState={this.getValidationState('state')}
              >
                <ControlLabel>Team</ControlLabel>{' '}
                <FormControl
                  componentClass="select"
                  placeholder="select"
                  value={this.state.productionLineItem.state}
                  onChange={this.handleChange}
                >
                  <option value="">Choose Team</option>
                  <option value="PRODUCED">Production</option>
                  <option value="RECEIVED">Warehouse</option>
                  <option value="QA">QA</option>
                </FormControl>
                <FormControl.Feedback />
              </FormGroup>{' '}
            </Form>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <BootstrapTable
              keyField="id"
              data={Object.keys(this.state.itemQts).map((item) => {
                const newItem = {
                  id: this.state.itemQts[item].itemName,
                  displayName: this.state.itemQts[item].itemName,
                  quantity: this.state.itemQts[item].quantity,
                  notes: this.state.itemQts[item].notes,
                  issueType: this.state.itemQts[item].issueType,
                  toProduce:
                    this.state.toProduce[this.state.itemQts[item].itemName],
                };
                if (this.state.productionLineItem.state === 'PRODUCED') {
                  newItem.line = this.state.itemQts[item].line;
                  newItem.linePopulation =
                    this.state.itemQts[item].linePopulation;
                  newItem.lineLead = this.state.itemQts[item].lineLead;
                  newItem.startTime = this.state.itemQts[item].startTime;
                  newItem.breakStartTime =
                    this.state.itemQts[item].breakStartTime;
                  newItem.breakEndTime = this.state.itemQts[item].breakEndTime;
                  newItem.endTime = this.state.itemQts[item].endTime;
                }
                return newItem;
              })}
              columns={[
                {
                  dataField: 'displayName',
                  text: 'Item',
                  editable: false,
                  filter: textFilter(),
                },
                {
                  dataField: 'toProduce',
                  text: 'Production Forecast',
                  editable: false,
                },
                {
                  dataField: 'line',
                  text: 'Line',
                  editor: {
                    type: Type.SELECT,
                    options: lineOptions,
                  },
                  validator: (newValue, row, column) => {
                    if (!this.state.selected.includes(row.id)) {
                      return {
                        valid: false,
                        message: 'Row must checked should not be empty',
                      };
                    }
                  },
                },
                {
                  dataField: 'linePopulation',
                  text: 'Line staff',
                  validator: (newValue, row) => {
                    if (newValue === 0 || newValue === '0') {
                      return {
                        valid: false,
                        message: 'Line Staff should not be 0',
                      };
                    }
                    if (newValue > 12) {
                      alert(
                        `you have entered in ${newValue}. Please confirm that this is the correct amount prior to submitting the checkpoint.`,
                      );
                      return {
                        valid: true,
                      };
                    }
                    if (newValue < 5) {
                      alert(
                        `you have entered in ${newValue}. Please confirm that this is the correct amount prior to submitting the checkpoint.`,
                      );
                      return {
                        valid: true,
                      };
                    }
                    if (!this.state.selected.includes(row.id)) {
                      return {
                        valid: false,
                        message: 'Row must checked should not be empty',
                      };
                    }
                    if (newValue === '') {
                      return {
                        valid: false,
                        message: 'Line Lead should not be empty',
                      };
                    }
                    if (newValue < -1) {
                      return {
                        valid: false,
                        message: 'Line population should great than -1',
                      };
                    }
                    return true;
                  },
                  editor: {
                    type: Type.NUMBER,
                  },
                },
                {
                  dataField: 'lineLead',
                  text: 'Line Lead',
                  validator: (newValue, row) => {
                    if (newValue === 'None') {
                      return {
                        valid: false,
                        message: 'Line Lead should not be empty',
                      };
                    }
                  },
                  editor: {
                    type: Type.SELECT,
                    options: lineLeadsOptions,
                  },
                },
                {
                  dataField: 'quantity',
                  text:
                    this.state.productionLineItem.state === 'PRODUCED'
                      ? 'Produced'
                      : 'Received',
                  validator: (newValue, row, column) => {
                    if (!this.state.selected.includes(row.id)) {
                      return {
                        valid: false,
                        message: 'Row should not be empty',
                      };
                    }
                    if (newValue === '') {
                      return {
                        valid: false,
                        message: 'Quantity should not be empty',
                      };
                    }
                    if (newValue === 0 || newValue === '0') {
                      return {
                        valid: false,
                        message: 'Quantity should not be 0',
                      };
                    }
                    if (isNaN(newValue)) {
                      return {
                        valid: false,
                        message: 'Quantity should be numeric',
                      };
                    }
                    if (newValue < -1) {
                      return {
                        valid: false,
                        message: 'Quantity should great than -1',
                      };
                    }

                    // This check displays a modal to ensure the user realizes they have inputted a quantity
                    // that is different by 10% from the forecasted amount
                    if (
                      newValue > row.toProduce * 1.1 ||
                      newValue < row.toProduce * 0.9
                    ) {
                      const confirmModal = this.state.confirmModal;
                      confirmModal.show = true;
                      confirmModal.quantity = newValue;
                      confirmModal.forecast = row.toProduce;
                      confirmModal.id = row.id;
                      this.setState({ confirmModal });
                    }

                    return true;
                  },
                  editor: {
                    type: Type.TEXT,
                  },
                },
                {
                  dataField: 'startTime',
                  text: 'Line Start time',
                  validator: (newValue, row) => {
                    const startTime = moment(newValue, 'HH:mm');
                    const found = newValue.match(
                      /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/,
                    );
                    if (!found || !startTime._isValid) {
                      return {
                        valid: false,
                        message:
                          'Only 24 hour time format allowed with atleast 2 digits',
                      };
                    }
                    const endTime = moment(row.endTime, 'HH:mm');
                    if (startTime.isAfter(endTime)) {
                      return {
                        valid: false,
                        message:
                          'End time is before start time, please update prior to submitting',
                      };
                    }
                    if (
                      endTime !== 'Invalid date' &&
                      startTime !== 'Invalid date'
                    ) {
                      const differentInMins = startTime.diff(
                        endTime,
                        'minutes',
                      );
                      if (differentInMins > -15) {
                        alert(
                          `The total time for this run is under 15 minutes. Please double check start and end time to make sure they are correct.`,
                        );
                        return {
                          valid: true,
                        };
                      }
                    }

                    this.setState({ disabled: true });
                    if (found.length === 1) {
                      this.setState({ disabled: false });
                      return {
                        valid: true,
                        message: 'Valid time',
                      };
                    }
                    return {
                      valid: false,
                      message: 'Invalid time',
                    };
                  },
                  editor: {
                    type: Type.TEXT,
                  },
                },
                {
                  dataField: 'endTime',
                  text: 'Line End time',
                  validator: (newValue, row) => {
                    const endTime = moment(newValue, 'HH:mm');
                    const found = newValue.match(
                      /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/,
                    );
                    if (!found || !endTime._isValid) {
                      return {
                        valid: false,
                        message:
                          'Only 24 hour time format allowed with at least 2 digits',
                      };
                    }
                    const startTime = moment(row.startTime, 'HH:mm');
                    if (endTime.isBefore(startTime)) {
                      return {
                        valid: false,
                        message:
                          'End time is before start time, please update prior to submitting',
                      };
                    }
                    if (
                      endTime !== 'Invalid date' &&
                      startTime !== 'Invalid date'
                    ) {
                      const differentInMins = startTime.diff(
                        endTime,
                        'minutes',
                      );
                      if (differentInMins > -15) {
                        alert(
                          `The total time for this run is under 15 minutes. Please double check start and end time to make sure they are correct.`,
                        );
                        return {
                          valid: true,
                        };
                      }
                    }
                    this.setState({ disabled: true });
                    if (found.length === 1) {
                      this.setState({ disabled: false });
                      return {
                        valid: true,
                        message: 'Valid time',
                      };
                    }
                    return {
                      valid: false,
                      message: 'Invalid time',
                    };
                  },
                  editor: {
                    type: Type.TEXT,
                  },
                },
                {
                  dataField: 'breakStartTime',
                  text: 'Break Start Time',
                  validator: (newValue, row) => {
                    const breakStartTime = moment(newValue, 'HH:mm');
                    const found = newValue.match(
                      /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/,
                    );
                    if (!found || !breakStartTime._isValid) {
                      return {
                        valid: false,
                        message:
                          'Only 24 hour time format allowed with atleast 2 digits',
                      };
                    }
                    const breakEndTime = moment(row.breakEndTime, 'HH:mm');
                    if (breakStartTime.isAfter(breakEndTime)) {
                      return {
                        valid: false,
                        message:
                          'End time is before start time, please update prior to submitting',
                      };
                    }
                    this.setState({ disabled: true });
                    if (found.length === 1) {
                      this.setState({ disabled: false });
                      return {
                        valid: true,
                        message: 'Valid time',
                      };
                    }
                    return {
                      valid: false,
                      message: 'Invalid time',
                    };
                  },
                  editor: {
                    type: Type.TEXT,
                  },
                },
                {
                  dataField: 'breakEndTime',
                  text: 'Break End Time',
                  validator: (newValue, row) => {
                    const breakEndTime = moment(newValue, 'HH:mm');
                    const found = newValue.match(
                      /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/,
                    );
                    if (!found || !breakEndTime._isValid) {
                      return {
                        valid: false,
                        message:
                          'Only 24 hour time format allowed with atleast 2 digits',
                      };
                    }
                    const breakStartTime = moment(row.breakStartTime, 'HH:mm');
                    if (breakEndTime.isBefore(breakStartTime)) {
                      return {
                        valid: false,
                        message:
                          'End time is before start time, please update prior to submitting',
                      };
                    }
                    this.setState({ disabled: true });
                    if (found.length === 1) {
                      this.setState({ disabled: false });
                      return {
                        valid: true,
                        message: 'Valid time',
                      };
                    }
                    return {
                      valid: false,
                      message: 'Invalid time',
                    };
                  },
                  editor: {
                    type: Type.TEXT,
                  },
                },
                {
                  dataField: 'issueType',
                  text: 'Delay Reason',
                  editor: {
                    type: Type.SELECT,
                    options: [
                      {
                        value: 'None',
                        label: 'None',
                      },
                      {
                        value: 'EQUIPMENT_MALFUNCTION',
                        label: '1 - Equipment Malfunction',
                      },
                      {
                        value: 'QFS_ISSUE',
                        label: '2 - QFS Issue',
                      },
                      {
                        value: 'REPROCESSING_ISSUE',
                        label: '3 - Reprocessing Issue',
                      },
                      {
                        value: 'COMPONENT_SEARCH_DELAY',
                        label: '4 - Component Search Delay',
                      },
                      {
                        value: 'UNPLANNED_OPERATOR_BREAK',
                        label: '5 - Unplanned Operator Break',
                      },
                    ],
                  },
                  validator: (newValue, row, column) => {
                    if (!this.state.selected.includes(row.id)) {
                      return {
                        valid: false,
                        message: 'Row must checked should not be empty',
                      };
                    }
                  },
                },
                {
                  dataField: 'notes',
                  text: 'Notes',
                  editor: {
                    type: Type.TEXTAREA,
                  },
                  validator: (newValue, row, column) => {
                    if (!this.state.selected.includes(row.id)) {
                      return {
                        valid: false,
                        message: 'Row must checked should not be empty',
                      };
                    }
                  },
                },
              ].filter((item) => {
                if (
                  this.state.productionLineItem.state !== 'PRODUCED' &&
                  [
                    'line',
                    'linePopulation',
                    'lineLead',
                    'startTime',
                    'endTime',
                    'breakStartTime',
                    'breakEndTime',
                  ].includes(item.dataField)
                ) {
                  return false;
                }
                return true;
              })}
              filter={filterFactory()}
              cellEdit={cellEditFactory({
                mode: 'click',
                autoSelectText: true,
                blurToSave: true,
                afterSaveCell: this.afterSaveCell,
              })}
              selectRow={{
                mode: 'checkbox',
                onSelect: this.handleOnSelect,
                selected: this.state.selected,
              }}
              pagination={paginationFactory()}
            />
          </Col>
        </Row>
        <Form>
          <FormGroup>
            <Col smOffset={2} sm={10}>
              <Button
                disabled={this.state.disabled}
                onClick={this.openSubmitModal}
              >
                Submit Checkpoint
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </div>
    );
  }
}
