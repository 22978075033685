import React, { useEffect, useState } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import LoaderButton from '../LoaderButton';
import { Items, EditOrderMutation } from '../../libs/GraphQL';
import styledComponent from '../../utils/styledComponent';
import ErrorsBlock from '../../atoms/ErrorsBlock';
import FileUpload from '../../atoms/FileUpload';

const LogModalTitle = styledComponent('h4', {
  borderBottom: '1px solid #e5e5e5',
  padding: '15px',
  margin: '0px',
});

const allowedFileColumns = [
  'Order ID',
  'Item Name',
  'Count',
  'Address',
  'Delivery Date',
  'Delivery Special Notes',
  'Market',
  'Drop Off Date',
];

const notReqFileColumns = [
  'Production Date',
  'Line Haul Departure Date',
  'PO Number',
];

const OrderFridgeMassUpdate = () => {
  const [fileContent, setFileContent] = useState([]);
  const [allowedItemsNames, setAllowedItemsNames] = useState(null);
  const [fileErrors, setFileErrors] = useState([]);
  const [lineErrors, setLineErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileChosen, setFileChosen] = useState(false);
  const [fileName, setFileName] = useState('');
  const [alertContent, setAlertContent] = useState([]);
  const [fileColumns, setFileColumns] = useState([]);

  const isNumber = (value) => !Number.isNaN(Number(value));

  const hasErrors = fileErrors.length > 0 || lineErrors.length > 0;

  const getVendItems = async () => {
    try {
      const itemsNames = [];
      const rawVendItems = await Items();
      const activeVendItems = rawVendItems.data.items.filter(
        (item) => item.status === 'LIVE',
      );
      activeVendItems.forEach((item) => {
        itemsNames.push(item.id);
      });
      setAllowedItemsNames(itemsNames);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getVendItems();
  }, []);

  const validateFile = (file) => {
    const errors = [];
    const fileHeaders = file.meta.fields;
    setFileColumns(fileHeaders);

    const columnsToValidate = fileHeaders.filter(
      (item) => !notReqFileColumns.includes(item),
    );

    const missedColumns = allowedFileColumns.filter(
      (x) => !columnsToValidate.includes(x),
    );
    const unknownColumns = columnsToValidate.filter(
      (x) => !allowedFileColumns.includes(x),
    );

    if (missedColumns.length) {
      errors.push(`Missed columns: ${missedColumns.join(', ')}`);
    }

    if (unknownColumns.length) {
      errors.push(
        `Unknown columns: ${unknownColumns.join(
          ', ',
        )}. File should contains following columns: ${allowedFileColumns.join(
          ', ',
        )}. And can contains optional columns: ${notReqFileColumns.join(', ')}`,
      );
    }

    return errors;
  };

  const itemDict = {};

  const validateLine = (line) => {
    const errors = [];
    const today = moment().format('YYYY-MM-DD');

    // Validate `Item Name` column
    if (line['Item Name'] === '') {
      errors.push('Value required for `Item Name` column');
    } else if (!allowedItemsNames.includes(line['Item Name'])) {
      errors.push('SKU in `Item Name` column does not match active SKU list');
    }

    // Validate duplicate `Item Name`
    if (!itemDict[line['Order ID']]) {
      itemDict[line['Order ID']] = [];
    }
    if (itemDict[line['Order ID']].includes(line['Item Name'])) {
      errors.push(
        `Duplicate item: ${line['Item Name']} in Order ID: ${line['Order ID']} remove and re-upload.`,
      );
    } else {
      itemDict[line['Order ID']].push(line['Item Name']);
    }

    // Validate `Count` column
    if (line.Count === '') {
      errors.push('Value required for `Count` column');
    } else if (!isNumber(line.Count)) {
      errors.push('`Count` must be a number');
    } else if (line.Count < 1) {
      errors.push('`Count` must have quantity greater than 0');
    }

    // Validate `Address` column
    if (line.Address === '') {
      errors.push('Value required for `Address`');
    }

    // Validate `Production Date` column
    const productionDateMoment = moment(
      line['Production Date'],
      'YYYY-MM-DD',
      true,
    );

    if (
      line['Production Date'] !== '' &&
      line['Production Date'] !== undefined &&
      !productionDateMoment.isValid()
    ) {
      errors.push(
        '`Production Date` contains invalid date. Date should be in `YYYY-MM-DD` format',
      );
    }

    // Validate `Delivery Date` column
    const deliveryDateMoment = moment(
      line['Delivery Date'],
      'YYYY-MM-DD',
      true,
    );

    if (line['Delivery Date'] === '') {
      errors.push('Value required for `Delivery Date` column');
    } else if (!deliveryDateMoment.isValid()) {
      errors.push(
        '`Delivery Date` contains invalid date. Date should be in `YYYY-MM-DD` format',
      );
    } else if (deliveryDateMoment.isBefore(productionDateMoment)) {
      errors.push('`Delivery Date` must come after `Production Date`');
    } else if (deliveryDateMoment.isSameOrBefore(today)) {
      errors.push('`Delivery Date` can not be before today');
    }

    // Validate `Drop Off Date` column
    const dropOffDateMoment = moment(line['Delivery Date'], 'YYYY-MM-DD', true);

    if (line['Drop Off Date'] === '') {
      errors.push('Value required for `Drop Off Date` column');
    } else if (!dropOffDateMoment.isValid()) {
      errors.push(
        '`Drop Off Date` contains invalid date. Date should be in `YYYY-MM-DD` format',
      );
    } else if (dropOffDateMoment.isBefore(productionDateMoment)) {
      errors.push('`Drop Off Date` must come after `Production Date`');
    } else if (dropOffDateMoment.isSameOrBefore(today)) {
      errors.push('`Drop Off Date` can not be before today');
    }

    // Validate `Market` column
    if (line.Market === '') {
      errors.push('Value required for `Market` column');
    }

    // Validate `Delivery Special Notes` column
    if (line['Delivery Special Notes'] === '') {
      errors.push('Value required for `Delivery Special Notes` column');
    }

    // Validate `Line Haul Departure Date` column
    if (
      line['Line Haul Departure Date'] !== undefined &&
      line['Line Haul Departure Date'] !== ''
    ) {
      const lineHaulDepartureDateMoment = moment(
        line['Line Haul Departure Date'],
        'YYYY-MM-DD',
        true,
      );

      if (!lineHaulDepartureDateMoment.isValid()) {
        errors.push(
          '`Line Haul Departure Date` contains invalid date. Date should be in `YYYY-MM-DD` format',
        );
      } else if (
        lineHaulDepartureDateMoment.isBefore(moment().format('YYYY-MM-DD'))
      ) {
        errors.push('`Line Haul Departure Date` cannot be in the past');
      }
    }

    // Validate `PO Number` column
    if (line['PO Number'] !== undefined && line['PO Number'] !== '') {
      if (String(line['PO Number']).length > 256) {
        errors.push('`PO Number` is too long');
      }
    }

    return errors;
  };

  const handleFileChange = (file) => {
    setFileErrors([]);
    setLineErrors([]);
    setFileChosen(false);
    setFileContent([]);
    setFileName(file.name);

    if (file.type !== 'text/csv') {
      setFileErrors(['File must be a .csv']);
    } else {
      window.Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (fileData) => {
          const fileValidationErrors = validateFile(fileData);
          if (fileValidationErrors.length) {
            setFileErrors(fileValidationErrors);
          } else {
            const linesValidationErrors = [];
            fileData.data.forEach((line, i) => {
              const lineValidationErrors = validateLine(line);
              if (lineValidationErrors.length) {
                linesValidationErrors.push({
                  location: `[Line ${i + 2}]`,
                  data: lineValidationErrors,
                });
              }
            });

            if (!linesValidationErrors.length) {
              setFileContent(fileData);
            } else {
              setLineErrors(linesValidationErrors);
            }
          }
        },
      });
    }
    setFileChosen(true);
  };

  const handleUpload = async () => {
    setLoading(true);
    const alertMessages = [];
    const ordersToEdit = Object.values(
      fileContent.data.reduce((acc, curr) => {
        if (!acc[curr['Order ID']]) {
          acc[curr['Order ID']] = {
            uniqueId: curr['Order ID'],
            deliveryDate: curr['Delivery Date'],
            lhDepartureDate: curr['Line Haul Departure Date'] || null,
            poNumber: String(curr['PO Number']) || null,
            itemsCount: [],
          };
        }

        acc[curr['Order ID']].itemsCount.push({
          itemName: curr['Item Name'],
          count: curr.Count,
          productionDate: curr['Production Date'],
        });

        return acc;
      }, {}),
    );

    // eslint-disable-next-line no-restricted-syntax
    for (const item of ordersToEdit) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await EditOrderMutation({
          uniqueId: item.uniqueId,
          itemsCount: item.itemsCount,
          deliveryDate: item.deliveryDate,
          ...(item.lineHaulDepartureDate && {
            lhDepartureDate: item.lineHaulDepartureDate,
          }),
          ...(item.poNumber && {
            poNumber: item.poNumber,
          }),
          modifiedBy: 'ORDER_MASS_UPDATE',
        });
        if (response.data.editOrder.code === 500) {
          throw new Error(`Error: ${item.uniqueId} was NOT updated`);
        } else {
          alertMessages.push(`${item.uniqueId} successfully updated`);
        }
      } catch (e) {
        if (e.message !== '') {
          alertMessages.push(e.message);
        } else {
          alertMessages.push(
            `Error: ${item.uniqueId} Unable to update order, Please check your internet and your permissions.`,
          );
        }
      }
    }

    setAlertContent(alertMessages);
    setFileContent([]);
    setFileChosen(false);
    setLoading(false);
  };

  return (
    <>
      <h3>Wholesale</h3>

      {fileChosen ? (
        <Button
          block
          bsStyle="primary"
          bsSize="large"
          style={{ marginBottom: '40px' }}
          disabled={loading}
          onClick={() => {
            setFileErrors([]);
            setLineErrors([]);
            setFileChosen(false);
            setFileContent([]);
          }}
        >
          Upload another file
        </Button>
      ) : (
        <FileUpload onFileChange={handleFileChange} />
      )}

      {hasErrors && (
        <ErrorsBlock>
          <ul>
            {fileErrors.map((error) => {
              return <li key={error}>{error}</li>;
            })}
            {lineErrors.map((error) => {
              return (
                <li key={error.location}>
                  <b>{error.location} has an error</b> <br />
                  {error.data.map((msg) => {
                    return <div key={msg}> {msg} </div>;
                  })}
                </li>
              );
            })}
          </ul>
        </ErrorsBlock>
      )}

      {fileContent.length !== 0 && !hasErrors && (
        <>
          <span>{fileName}</span>
          <Table>
            <thead style={{ border: '1px solid #ddd' }}>
              <tr>
                {fileColumns.map((col) => {
                  return <th key={col}>{col}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {fileContent &&
                fileContent.data.map((line) => {
                  return (
                    <tr key={line.Address + line['Item Name']}>
                      {fileColumns.map((col) => {
                        return (
                          <td key={col}>
                            {typeof line[col] === 'boolean'
                              ? line[col].toString()
                              : line[col]}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <LoaderButton
            block
            bsStyle="success"
            bsSize="large"
            type="submit"
            isLoading={loading}
            text="Run Order Mass Update"
            loadingText="Orders Updating…"
            style={{ marginBottom: '40px' }}
            onClick={handleUpload}
          />
        </>
      )}

      <Modal show={alertContent.length}>
        <LogModalTitle>Order Mass Update Log</LogModalTitle>
        <Modal.Body>
          {alertContent.map((msg) => (
            <div key={msg} style={{ marginBottom: '8px' }}>
              {msg}
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="warning"
            onClick={() => {
              setAlertContent([]);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrderFridgeMassUpdate;
