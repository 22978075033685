import React, { Component } from 'react';
import DatePicker from 'react-date-picker';
import {
  Modal,
  Col,
  Row,
  Button,
  Table,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
} from 'react-bootstrap';
import {
  ProductionLineSummary,
  DeleteProductionLineCoCMutation,
} from '../../libs/GraphQL';
import LoaderButton from '../LoaderButton';
import EditProductionLineItemModal from './EditProductionLineItemModal';
import { toast } from 'react-toastify';

export default class ProductionLineItemSummary extends Component {
  constructor(props) {
    const currentDate = new Date(
      new Date().getTime() + new Date().getTimezoneOffset() * 60 * 1000,
    );
    currentDate.setHours(
      new Date().getHours() - new Date().getTimezoneOffset() / 60,
    );
    super(props);
    this.state = {
      alertModal: {
        mode: false,
        text: '',
      },

      isLoading: false,
      itemsList: [],
      ownerList: [],
      itemsCount: {},
      summary: [],
      filteredSummary: [],
      productionDate: currentDate,
      productionFacility: 'CHICAGO_1',
      selectedItem: '',
      selectedOwner: '',
      isEditItem: false,
      editItem: {},
      deleteModal: {
        show: false,
        id: '',
      },
      deleteLoading: false,
    };
    this.queryData = this.queryData.bind(this);
    this.formSummary = this.formSummary.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleEditClose = this.handleEditClose.bind(this);
    this.handleEditConfirm = this.handleEditConfirm.bind(this);
    this.generateRows = this.generateRows.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleEditClick(item) {
    this.setState({ isEditItem: true, editItem: item });
  }

  handleEditClose() {
    this.setState({ isEditItem: false, editItem: {} });
  }

  handleEditConfirm() {
    this.queryData();
    this.setState({ isEditItem: false, editItem: {} });
  }

  handleDeleteClick(id) {
    this.setState({ deleteModal: { show: true, id } });
  }

  async handleDeleteConfirm() {
    try {
      this.setState({ deleteLoading: true });
      const response = await DeleteProductionLineCoCMutation(
        this.state.deleteModal.id,
      );

      toast.success(response.message, {
        className: 'prettyToast',
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'light',
      });

      this.setState({ deleteModal: { show: false, id: '' } });
    } catch (e) {
      this.setState({ deleteModal: { show: false, id: '' } });
      toast.error(e.message || 'Unknown error occurred during deleting', {
        className: 'prettyToast',
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'light',
      });
    } finally {
      this.setState({ deleteLoading: false });
      this.queryData();
    }
  }

  async queryData() {
    let alertModal;
    if (
      this.state.productionDate === '' ||
      this.state.productionFacility === ''
    ) {
      alertModal = this.state.alertModal;
      alertModal.mode = true;
      alertModal.text = 'Production Date and facility cannot be null';
      this.setState({ alertModal });
      return;
    }
    const pDate = JSON.parse(JSON.stringify(this.state.productionDate));
    const pFacility = this.state.productionFacility;
    this.getproductionLineSummary(pDate.substring(0, 10), pFacility);
  }

  compare(a, b) {
    if (a.productionTime > b.productionTime) {
      return 1;
    }
    if (b.productionTime > a.productionTime) {
      return -1;
    }
    return 0;
  }

  handleFilterChange(event) {
    this.setState({ [event.target.name]: event.target.value }, () => {
      const filteredSummary = [];
      for (const i in this.state.summary) {
        let include = true;
        if (
          this.state.selectedItem === '' ||
          (this.state.selectedItem !== '' &&
            this.state.summary[i].itemName === this.state.selectedItem)
        ) {
          include = true;
        } else {
          include = false;
        }
        if (
          this.state.selectedOwner === '' ||
          (this.state.selectedOwner !== '' &&
            this.state.summary[i].owner === this.state.selectedOwner)
        ) {
          include = include && true;
        } else {
          include = include && false;
        }
        if (include) {
          filteredSummary.push(this.state.summary[i]);
        }
      }
      this.setState({ filteredSummary });
    });
  }

  formSummary(summaryFromServer) {
    const rows = [];
    for (const index in summaryFromServer) {
      const summary = summaryFromServer[index];
      const thisTime = new Date(summary.productionTime * 1000);
      summary.productionTime = thisTime.toLocaleTimeString('en-US', {
        hour12: false,
      });
      rows.push(summary);
      rows.sort(this.compare);
    }
    this.setState({ summary: rows, filteredSummary: rows });
  }

  async getproductionLineSummary(productionDate, productionFacility) {
    this.setState({ isLoading: true });
    const itemsList = new Set();
    const ownerList = new Set();
    const itemsCount = {};
    itemsCount.PRODUCED = new Set();
    itemsCount.RECEIVED = new Set();
    try {
      const summary = await ProductionLineSummary(
        productionDate,
        productionFacility,
      );
      const thisSummary = summary.data.productionLineItemSummary;
      for (const i in thisSummary) {
        if ('itemName' in thisSummary[i]) {
          itemsList.add(thisSummary[i].itemName);
        }
        if ('owner' in thisSummary[i]) {
          ownerList.add(thisSummary[i].owner);
        }
        if ('state' in thisSummary[i]) {
          itemsCount[thisSummary[i].state].add(thisSummary[i].itemName);
        }
      }
      this.formSummary(summary.data.productionLineItemSummary);
      this.setState({
        isLoading: false,
        itemsList: Array.from(itemsList).sort(),
        ownerList: Array.from(ownerList).sort(),
        itemsCount,
        selectedItem: '',
        selectedOwner: '',
      });
    } catch (e) {
      console.error(e);
      const alertModal = this.state.alertModal;
      alertModal.mode = true;
      alertModal.text =
        'Unable to load Production Line Summary, Please check your internet and your permissions';
      this.setState({ alertModal });
    }
  }

  generateRows(item) {
    return (
      <tr key={`${item.productionTime} ${item.itemName}`}>
        <td>{item.productionTime}</td>
        <td>{item.itemName}</td>
        <td>{item.state}</td>
        <td>{item.quantity}</td>
        <td style={{ whiteSpace: 'nowrap' }}>
          {item.hasOwnProperty('line') ? item.line : ' '}
        </td>
        <td>{item.hasOwnProperty('lineLead') ? item.lineLead : ' '}</td>
        <td>
          {item.hasOwnProperty('linePopulation') ? item.linePopulation : ' '}
        </td>
        <td>{item.hasOwnProperty('startTime') ? item.startTime : ' '}</td>
        <td>{item.hasOwnProperty('endTime') ? item.endTime : ' '}</td>
        <td>
          {item.hasOwnProperty('breakStartTime') ? item.breakStartTime : ' '}
        </td>
        <td>{item.hasOwnProperty('breakEndTime') ? item.breakEndTime : ' '}</td>
        <td>{item.owner}</td>
        <td>{item.uniqueId}</td>
        {this.props.allowEditAndDeleteProductionLine && (
          <td style={{ whiteSpace: 'nowrap' }}>
            <button
              className="btn btn-info"
              type="button"
              onClick={() => this.handleEditClick(item)}
              disabled={item.state !== 'PRODUCED'}
            >
              EDIT ITEM
            </button>
            <button
              className="btn btn-danger"
              style={{ marginLeft: '8px' }}
              type="button"
              onClick={() => this.handleDeleteClick(item.uniqueId)}
              disabled={item.state !== 'PRODUCED'}
            >
              DELETE
            </button>
          </td>
        )}
      </tr>
    );
  }

  render() {
    return (
      <div>
        <h2>Production Line Summary</h2>
        {this.state.alertModal.mode && (
          <Modal show={this.state.alertModal.mode}>
            <Modal.Body>
              <div className="form-group">{this.state.alertModal.text}</div>
            </Modal.Body>
            <Modal.Footer>
              &nbsp;
              <Button
                bsStyle="warning"
                onClick={() => {
                  const alertModal = this.state.alertModal;
                  alertModal.mode = false;
                  this.setState({ alertModal });
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {this.state.deleteModal.show && (
          <Modal show={this.state.deleteModal.show}>
            <Modal.Header>
              <h3>
                <b>CONFIRM DELETE</b>
              </h3>
            </Modal.Header>
            <Modal.Body>
              <h4 style={{ lineHeight: '140%' }}>
                Are you sure that you want to delete{' '}
                <b>{this.state.deleteModal.id}</b> item?
              </h4>
              <h4 style={{ color: '#a60303' }}>
                This action cannot be undone!
              </h4>
            </Modal.Body>
            <Modal.Footer>
              <Button
                bsStyle="secondary"
                onClick={() => {
                  this.setState({ deleteModal: { show: false, id: '' } });
                }}
                disabled={this.state.deleteLoading}
              >
                NO, GO BACK
              </Button>
              <Button
                bsStyle="danger"
                onClick={() => {
                  this.handleDeleteConfirm(this.state.deleteModal.id);
                }}
                disabled={this.state.deleteLoading}
              >
                {this.state.deleteLoading ? 'DELETING ITEM...' : 'YES, DELETE'}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        <Row>
          <Col sm={12}>
            <br />
            <Form inline>
              <FormGroup>
                <ControlLabel>Production Facility</ControlLabel>{' '}
                <DatePicker
                  name="productionDatePicker"
                  onChange={(date) => this.setState({ productionDate: date })}
                  value={this.state.productionDate}
                />
              </FormGroup>{' '}
              <FormGroup controlId="productionFacility">
                <ControlLabel>Production Facility</ControlLabel>{' '}
                <FormControl
                  componentClass="select"
                  placeholder="select"
                  value={this.state.productionFacility}
                  onChange={this.handleChange}
                >
                  <option value="">Choose Facility</option>
                  <option value="CHICAGO_1">CHICAGO_1</option>
                </FormControl>
                <FormControl.Feedback />
              </FormGroup>
              {'   '}{' '}
              <Button bsStyle="success" onClick={this.queryData}>
                Fetch Data
              </Button>
            </Form>
            <hr />
          </Col>
        </Row>
        <br />
        {this.state.itemsList.length !== 0 && (
          <div>
            <div
              style={{
                float: 'right',
                border: '1px solid',
                padding: '5px',
                marginBottom: '10px',
              }}
            >
              <h4>
                Total Unique Items Produced:{' '}
                {this.state.itemsCount.PRODUCED.size}
              </h4>
              <h4>
                Total Unique Items Received:{' '}
                {this.state.itemsCount.RECEIVED.size}
              </h4>
            </div>
            <Col sm={4} xs={12}>
              <select
                name="selectedItem"
                value={this.state.selectedItem}
                className="form-control"
                onChange={this.handleFilterChange}
                selected={this.state.selectedItem}
              >
                <option value="">All Items in this time range</option>
                {Object.keys(this.state.itemsList).map((user) => (
                  <option key={user} value={this.state.itemsList[user]}>
                    {this.state.itemsList[user]}
                  </option>
                ))}
              </select>
            </Col>
            <Col sm={4} xs={12}>
              <select
                name="selectedOwner"
                value={this.state.selectedOwner}
                className="form-control"
                onChange={this.handleFilterChange}
                selected={this.state.selectedOwner}
              >
                <option value="">All Owners in this time range</option>
                {Object.keys(this.state.ownerList).map((fridge) => (
                  <option key={fridge} value={this.state.ownerList[fridge]}>
                    {this.state.ownerList[fridge]}
                  </option>
                ))}
              </select>
              <br />
            </Col>
          </div>
        )}
        {this.state.isLoading && (
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled=""
            type="submit"
            isLoading={this.state.isLoading}
            text=""
            loadingText="Loading Data"
          />
        )}
        {this.state.summary.length === 0 && (
          <div className="NotFound">
            <h3>NO Data to Load</h3>
          </div>
        )}
        {this.state.summary.length !== 0 && (
          <Table style={{ borderRight: '100px solid white' }} hover>
            <tbody>
              <tr>
                <th>Creation Time</th>
                <th>Item</th>
                <th>State</th>
                <th>Quantity</th>
                <th>Line</th>
                <th>Line Lead</th>
                <th>Line Staff</th>
                <th>Line Start Time</th>
                <th>Line End Time</th>
                <th>Break Start Time</th>
                <th>Break End Time</th>
                <th>Owner name</th>
                <th>Unique ID</th>
                {this.props.allowEditAndDeleteProductionLine && <th />}
              </tr>
              {this.state.filteredSummary.map(this.generateRows)}
            </tbody>
          </Table>
        )}

        {this.state.isEditItem && (
          <EditProductionLineItemModal
            onClose={() => this.handleEditClose()}
            onConfirm={() => this.handleEditConfirm()}
            item={this.state.editItem}
          />
        )}
      </div>
    );
  }
}
